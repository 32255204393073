/* Just flexing my CSS skills ᕙ༼◕ ᴥ ◕༽ᕗ */

.hero--banner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.hero--banner, .column {
    gap: 16px;
}

.hero--banner img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    width: 100%;
    min-height: 140px;
    align-self: center;
    border-radius: 16px;
}

.hero--banner .column:nth-child(1) {
    margin-top: 96px;
}

.hero--banner .column:nth-child(2) {
    margin-top: 16px;
}

.hero--banner .column:nth-child(2) img:nth-child(1) {
    height: 30%;
    min-height: 30%;
    object-position: center 25%;
}

.hero--banner .column:nth-child(3) {
    margin-top: 64px;
}

.hero--banner .column:nth-child(4) img:nth-child(2) {
    object-position: right;
}

.hero--banner .column:nth-child(5) {
    margin-top: 32px;
}

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

@media (max-width: 600px) {
    .hero--banner .column:nth-child(1), .hero--banner .column:nth-child(5) {
        display: none;
    }
}

@media (min-width: 900px) and (max-width: 1250px) {
    .hero--banner .column:nth-child(1) {
        display: none;
    }
}