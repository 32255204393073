body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
}
header {
    padding: 22px 30px;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
    height: 40px;
}

header img {
    height: 100%;
}

.hero {
    margin: 40px;
}

.hero--banner {
    margin: 40px auto;
    width: 720px;
    max-width: 100%;
}

.hero--text {
    width: 480px;
    font-size: 24px;
    max-width: 100%;
}

.hero--text h1 {
    font-size: 48px;
}

.hero--text p, .hero--text h1{
    margin-top: 16px;
    margin-bottom: 16px;
}

.app--card_list {
    display: flex;
    gap: 32px;
    overflow-x: scroll;
}

.card {
    flex: 0 0 auto;
    margin-bottom: 24px;
}

.card:first-child {
    margin-left: 40px;
}

.card:last-child {
    padding-right: 40px; /* margin doesn't work right in Safari */
}

@media (min-width: 900px) {
    .hero {
        margin-left: auto;
        margin-right: auto;
        width: 1200px;
        max-width: 90%;
        display: flex;
        gap: 48px;
        flex-direction: row-reverse;
        justify-content: center;
        flex: 1;
    }
}