.card {
    width: 240px;
    height: 400px;
    font-weight: 300;
    position: relative;
}

.card--cover {
    object-fit: cover;
    border-radius: 16px;
    width: 100%;
    height: 75%;
}

.card--availability {
    text-transform: uppercase;
    position: absolute;
    font-size: 12px;
    left: 8px;
    top: 8px;
    padding: 2px 8px;
    background-color: #FFF;
    border-radius: 4px;
}

.card--text--upper {
    margin-top: 8px;
    color: #918E9B;
}

.card--text--rating{
    color: #000;
}

.card--text--rating img {
    margin-right: 2px;
    width: .85em;
    height: .8em;
    vertical-align: baseline;
}

.card--text--pricing b {
    font-weight: 600;
}

.card--text--description, .card--text--pricing {
    margin-top: 8px;
}